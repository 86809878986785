import { Component, Input, OnInit, Output ,EventEmitter} from '@angular/core';
import { DataCart } from 'src/app/modales/cart';
import { CartService } from 'src/app/services/cart/cart.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {

  @Input() item : DataCart;
  @Output() addToCart: EventEmitter<string> =   new EventEmitter();
  public changeCount  = 1;
  cart :DataCart[] ;
  totalValue;
  
  bodyData : FormData = new FormData;
  loadingCart =false;
  cartEmpty = false;
  constructor(private http : CartService) { }

  ngOnInit(): void {
  }
  travelersMinus(evnet: Event){
    if(this.changeCount - 1 >= 1 ){
       this.changeCount -= 1;
     }
  }
  travelersPlus(evnet: Event){
    if(this.changeCount > 0 ){
      this.changeCount += 1;
    }
  }
  clickSweet(id){
    this.bodyData.append('product_id' ,id ),
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.http.deleteProduct(this.bodyData).subscribe(response =>{
          if(response.status == 1){
            this.addToCart.emit('user Edited Successfully');
            Swal.fire(
              'Deleted!',
              'Your imaginary file has been deleted.',
              'success'
            )
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }

}

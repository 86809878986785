<div id="wrapper">
  <!-- <app-left-sidebar ></app-left-sidebar> -->
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->

  <div class="content-page">
    <app-topbar *ngIf="router.url != '/splashScreen'"></app-topbar>
      <!-- Start Page content -->
      <router-outlet></router-outlet>
       <!-- content -->

  </div>
</div>
<!-- *ngIf="router.url != '/login'" -->
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CallBackitem } from 'src/app/callback_services/callback';
import { DataCart } from 'src/app/modales/cart';
import { CartService } from 'src/app/services/cart/cart.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  cart :DataCart[] ;
  cartCount = 0 ;
  constructor(private router: Router,private http : CartService) { }

  userName
  
  ngOnInit(): void {
    this.userName = localStorage.getItem('name');
    this.http.getCart().subscribe(response =>{
      this.cart =response.data;
      this.cartCount = response.data.length;
      console.log(this.cartCount)
    })
    // this.callback.counteChange.subscribe( (count : boolean ) => {
    //   this.headerCounter();
    // })
  }

  headerCounter(){
    this.http.getCart().subscribe(response =>{
      // this.CounterCart = response.data
      this.cartCount = response.data.length;

    })
  }
  
}

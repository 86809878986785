import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeftSidebarComponent } from './components/items/left-sidebar/left-sidebar.component';
import { TopbarComponent } from './components/items/topbar/topbar.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { ProductDetailsComponent } from './components/pages/product-details/product-details.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ShopingCartComponent } from './components/pages/shoping-cart/shoping-cart.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { SplahScreenComponent } from './components/pages/splah-screen/splah-screen.component';
import { SingleProductComponent } from './components/items/single-product/single-product.component';
import { SaveOrderComponent } from './components/pages/save-order/save-order.component';
import { FormsModule } from '@angular/forms';
import { CartItemComponent } from './components/items/cart-item/cart-item.component';

@NgModule({
  declarations: [
    AppComponent,
    LeftSidebarComponent,
    TopbarComponent,
    ProductsComponent,
    ProductDetailsComponent,
    ShopingCartComponent,
    SplahScreenComponent,
    SingleProductComponent,
    SaveOrderComponent,
    CartItemComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataCart } from 'src/app/modales/cart';
import { Product } from 'src/app/modales/product';
import { CartService } from 'src/app/services/cart/cart.service';
import { ProductService } from 'src/app/services/products/product.service';
import Swal from 'sweetalert2';
import {DataCategories} from '../../../modales/category';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})    
export class ProductsComponent implements OnInit {

  selectedCategoryId: number;
  categories :DataCategories[];
  products :Product[];
  cart :DataCart[];
  loading = false;
  loadingProduct = false; 
  cartEmpty =false;
  totalPrice ;
  value;
  qty = 1;
  bodyData : FormData = new FormData;
  arr = [];
  totalValue;
  loadingCart = false;
  constructor(private httpCategory : ProductService,public router: Router,private http : CartService) { 

  }

  ngOnInit(): void {
    this.loading=true;
    this.httpCategory.getAllCategories().subscribe(response =>{
      this.loading=false;
      this.categories = response.data;
      if(this.categories?.length>0)
      this.ShowProduct(this.categories[0]?.id);
    })
    this.http.getCart().subscribe(response =>{
      this.cart =response.data;
      if(this.cart?.length > 0){
        this.totalValue = this.cart.filter((item) =>item.price)
                            .map((item) => +item.price)
                            .reduce((sum, current) => sum + current);
      }

      if(this.cart?.length == 0){
        this.cartEmpty = true;
      }
    })
  }
  ShowProduct(id){
    this.selectedCategoryId = id;
    this.getProduct(this.selectedCategoryId);
  }

  getProduct(id){
    this.loadingProduct=true;
    this.httpCategory.getAllProducts(id).subscribe(response =>{
      this.products = response.data.products;
      this.loadingProduct=false;
    })
  }


  AddProduct(e){
    this.http.getCart().subscribe(response =>{
      this.cartEmpty = false;
      this.cart = response.data;
      if(this.cart?.length > 0){
        this.totalValue = this.cart.filter((item) =>item.price)
                            .map((item) => +item.price)
                            .reduce((sum, current) => sum + current);
      }

      if(this.cart?.length == 0){
        this.cartEmpty = true;
      }
    })
  }
  removeAll(){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.http.deketeAll().subscribe(response =>{
          if(response.status == 1){
            this.http.getCart().subscribe(response =>{
              this.loadingProduct=false;
              this.cart =response.data;
              if(this.cart?.length > 0){
                this.totalValue = this.cart.filter((item) =>item.price)
                                    .map((item) => +item.price)
                                    .reduce((sum, current) => sum + current);
              }
              if(this.cart?.length === 0){
                this.cartEmpty = true;
                
              }
            })
            Swal.fire(
              'Deleted!',
              'Your imaginary file has been deleted.',
              'success'
            )
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }
  
}

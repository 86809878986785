import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/Auth/login.service';


@Component({
  selector: 'app-splah-screen',
  templateUrl: './splah-screen.component.html',
  styleUrls: ['./splah-screen.component.scss']
})
export class SplahScreenComponent implements OnInit {

  deviceInfo;
    constructor(private router: Router,private services :LoginService) {
      this.epicFunction();
      this.services.login(this.deviceInfo).subscribe(response =>{
        if(response.status == 1){
         this.router.navigate(['/product']);
         localStorage.setItem('token',response.data.token);
        }
      },);

    }
    epicFunction() {
      var navigator_info = window.navigator;
      var screen_info = window.screen;
      var uid = navigator_info.mimeTypes.length+"";
      uid += navigator_info.userAgent.replace(/\D+/g, '');
      uid += navigator_info.plugins.length;
      uid += screen_info.height || '';
      uid += screen_info.width || '';
      uid += screen_info.pixelDepth || '';
      console.log(uid);
      this.deviceInfo = uid;
    }

  ngOnInit(): void {
  }

}

<div class="shopping_cart Carts">
    <h3> قائمة التسوق</h3>
    
    <div class="all_item">
        <div *ngFor='let item of cart'>
            <hr>
            <div class="descDiv">
                <h5 class="card-title mt-2">{{item.name}}
                </h5>    
                <p>{{item.price}} {{item.currency}} </p>
            </div>
            <img src="{{item.image}}">
        </div>
    </div>

</div>
<div class="content">
    <div class="container-fluid">
        
        <div class="row" >
            <div class="col-md-8">
                <div class="card-box">
                    <div class="desProduct checkout">
                        <h4 class="modal-title" >ادخال البيانات الخاصة بك</h4>
                    </div>
                </div>
                <div class="card-box">
                    <div class="desProduct checkout">
                        <form >
                            <!-- #data="ngForm" (ngSubmit)='addProduct(data)' -->
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">الاسم<span class="text-danger">*</span></label>
                                        <input type="text" required
                                        [(ngModel)]="nameModel"
                                                class="form-control"  name="image_link"  >
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">رقم الهاتف<span class="text-danger">*</span></label>
                                        <input type="text"  required  
                                        [(ngModel)]="phoneModel"
                                                class="form-control"  name="name" >
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">المنطقة<span class="text-danger">*</span></label>
                                        <select class="custom-select"  [(ngModel)]="selectedCountry" name="selectedCountry" (change)="onCuntrySelected()">
                                            <option select >اختر...</option>
                                            <option *ngFor = "let item of zone" [ngValue]="item">{{item.zone_name}}</option>
                                        </select>
                                    

                                        
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">العنوان<span class="text-danger">*</span></label>
                                        <input type="text"  required  
                                        [(ngModel)]="addressModel"
                                                class="form-control"  name="id" >
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="alert" role="alert" #errorDiv>
                                         <!-- {{errorMessage}} -->
                                      </div>
                                </div>
                            </div>
                            
                            <div class="form-group text-right m-b-0">
                                <button class="btn btn-custom waves-effect waves-light AddUser" type="submit" (click)="saveOrder()">
                                    ارسال البيانات 
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="desProduct checkout">
                    <h4 class="modal-title" >الحسابات</h4>
                </div>
                <div class="desProduct checkout">
                    <ul class="priceCart">
                        <li>سعر المنتجات<span>{{totalValue}}</span></li>
                        <li>الخصم <span>-{{discount}}</span></li>
                        <li>سعر التوصيل<span>{{shippingPrice}}</span></li>
                        <hr>
                        <li>اجمالي المبلغ<span>{{netPrice}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div> <!-- container -->
</div>
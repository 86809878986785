<div class="card">
    <div class="card-block">
        <a  (click)="singleProduct(item.product_id)">
            <h4> {{item.name}} <span><i class="fa fa-shopping-cart"></i></span></h4>
        <hr>
        <div style="display: flex;margin-bottom: 20px;">
            <img src="{{item.image}}">
        </div>
        </a>
        <h4 class="card-title mt-2">{{item.price}} {{item.currency}}
            <a class="add_cart loadingButton" *ngIf="loadingCart"><i class="fa fa-circle-o-notch fa-spin"></i></a>
            <a class="add_cart" (click)="submitCart(item.id)" *ngIf="!loadingCart">
               اضافة الي السلة  <i class="fa fa-shopping-cart"></i>
            </a>
        </h4>    
    </div> 
</div>
import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/modales/product';
import { CartService } from 'src/app/services/cart/cart.service';
// import { CallBackitem } from 'src/app/callback_services/callback';

@Component({
  selector: 'app-single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.scss']
})
export class SingleProductComponent implements OnInit {

  @Input() item : Product;
  @Output() childEdited: EventEmitter<string> =   new EventEmitter();
  bodyData : FormData = new FormData;
  loadingCart =false;
  qty = 1;
  constructor(private http : CartService,public router: Router, ) { }

  ngOnInit(): void {
  }
  submitCart(id){
    this.loadingCart = true;

    this.bodyData.append('qty' ,this.qty+'' ),
    this.bodyData.append('product_id' ,id ),
    this.http.addProduct(this.bodyData).subscribe(response =>{
      this.loadingCart = false;
      this.childEdited.emit('user Edited Successfully');
      // this.callback.counteChange.emit(true);
      
    })
    
  }
  singleProduct(id){
    this.router.navigate(["/productDetaile", { 'productDetaileId': id }]);
  }

}

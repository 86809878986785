
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { General, ShoppingCart } from 'src/app/modales/cart';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  BaseUrl ='http://api.spedosales.apishipping.iraq-soft.info/'
  token :string;
  lang :string;
  constructor(private http: HttpClient) { }

  
  public getCart():  Observable<ShoppingCart>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<ShoppingCart>(this.BaseUrl+ 'api/cart/get' ,{headers});
  }

  public addProduct(body):  Observable<General>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<General>(this.BaseUrl+ 'api/cart/add' ,body,{headers});
  }

  public deleteProduct(body):  Observable<General>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<General>(this.BaseUrl+ 'api/cart/remove' ,body,{headers});
  }

  public deketeAll():  Observable<General>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<General>(this.BaseUrl+ 'api/cart/remove-all' ,null,{headers});
  }
  public SaveOrder(body : FormData):  Observable<General>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<General>(this.BaseUrl+ 'api/web/save-order' ,body,{headers});
  }
  public Discount(total_price):  Observable<General>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<General>(this.BaseUrl+ 'api/discount/get?total_price='+total_price +'&order_type=1' ,{headers});
  }
}

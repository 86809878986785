    <!-- Start Page content -->
    <div class="content">
        <div class="container-fluid">

            <div class="card-deck mt-5" style="min-height: 500px;" *ngIf="loadingProduct">
                <div class="logoIcon">
                    <img class="loadingPage" style="width: 80px; height: 80px;    top: 40%;
                    position: absolute;
                    left: 45%;" src="../../../../assets/images/loading.jpg">
                </div>  
        </div>
            <div class="row" *ngIf="!loadingProduct">
                <div class="col-12">
                    <div >
                        <h2>المنتج</h2>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="desProduct">
                        <h3>{{productDetailse.name}}</h3>
                        <hr>
                        <div class="disc">
                            <h5>الوصف : </h5>
                            <p>{{productDetailse.desc}}</p>
                            <hr>
                            <table>
                                <tr>
                                    <th>Display</th>
                                    <td>when an unknown printer took a galley of type and scrambled</td>
                                </tr>
                                <tr>
                                    <th>Processor</th>
                                    <td>when an unknown printer took a galley</td>
                                </tr>
                                <tr>
                                    <th>Camera</th>
                                    <td>when an unknown printer took a galley of type and scrambled</td>
                                </tr>
                                <tr>
                                    <th>Memory</th>
                                    <td>when an unknown printer took a galley </td>
                                </tr>
                                <tr>
                                    <th>Display</th>
                                    <td>when an unknown printer took a galley </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="desProduct">
                        <h3>{{productDetailse.price}}{{productDetailse.currency}}
                            <a class="add_cart loadingButton" *ngIf="loadingCart"><i class="fa fa-circle-o-notch fa-spin"></i></a>
                            <a class="add_cart" (click)="submitCart(productDetailse.id)" *ngIf="!loadingCart">
                            اضافة الي السلة  <i class="fa fa-shopping-cart"></i>
                            </a>
                        </h3>
                    </div>
                    
                    <div class="desProduct" style="direction: ltr;">
                        <img src="{{ productDetailse.image }}" alt="" width="100%">
                        <!-- <ngx-slick-carousel class="carousel productSlide" 
                        #slickModal="slick-carousel" 
                        [config]="productSlide" >
                            <div ngxSlickItem *ngFor="let item of productDetailse" class="slide">
                                <img src="{{ item.image }}" alt="" width="100%">
                            </div>
                        </ngx-slick-carousel>
                        <ngx-slick-carousel class="carousel sliderFor" 
                                #slickModal="slick-carousel"
                                [config]="sliderFor">
                                <div ngxSlickItem *ngFor="let item of productDetailse" class="slide img_sm">
                                    <img  src="{{ item.image }}">
                                </div>
                        </ngx-slick-carousel> -->
                    </div>
                </div>
            </div>
        </div> <!-- container -->
    </div> <!-- content -->





    
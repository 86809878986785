import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataCart } from 'src/app/modales/cart';
import { DataZones } from 'src/app/modales/zonse';
import { CartService } from 'src/app/services/cart/cart.service';
import { ZoneService } from 'src/app/services/zones/zone.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-save-order',
  templateUrl: './save-order.component.html',
  styleUrls: ['./save-order.component.scss']
})
export class SaveOrderComponent implements OnInit {

  cart :DataCart[];
  loading = true;
  zone :DataZones[];
  totalValue
  discountPrcent;
  netPrice ; 
  discount;
  nameModel ;
  phoneModel;
  addressModel ;
  shippingPrice:string = "0";
  selectedCountry :DataZones;
  body :FormData = new FormData() ; 
  errorMsg : string ; 
  constructor(private http : CartService , private httpZones :ZoneService , private router : Router) { }

  ngOnInit(): void {
    this.http.getCart().subscribe(response =>{
      this.cart =response.data;
      if(this.cart?.length > 0){
        this.totalValue = this.cart.filter((item) =>item.price)
                            .map((item) => +item.price)
                            .reduce((sum, current) => sum + current);
                            this.http.Discount(this.totalValue).subscribe(response=>{
                              this.discountPrcent = response.data;
                              this.discount = (this.totalValue * this.discountPrcent)/100;
                              this.clucNetSalary()
                              console.log(this.discountPrcent)
                            })
      }
    })
    this.httpZones.getZones().subscribe(response =>{
      this.zone = response.data;
    })
    
    
  }
  onCuntrySelected(){
    console.log(this.selectedCountry.shipping_price)
    this.shippingPrice = this.selectedCountry.shipping_price;
    this.clucNetSalary()

  }

  clucNetSalary(){
    this.netPrice = parseInt(this.totalValue) + parseInt(this.shippingPrice) - parseInt(this.discount)
  }

  saveOrder(){
    this.body.append("name" , this.nameModel);
    this.body.append("phone" , this.phoneModel);
    this.body.append("address" , this.addressModel);
    this.body.append("total_price" , this.totalValue);
    this.body.append("discount" , this.discount);
    this.body.append("order_type" , 1+"");
    this.body.append("zone_id" , this.selectedCountry?.id +"");
    this.http.SaveOrder(this.body).subscribe(res =>{
        if(res.status == 1){
          Swal.fire({
            title: 'Success',
            text: res.message,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then((result=>{
            this.router.navigate(["/product"]);
          }))
        }
    });
  }

  
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoriesResponse } from '../../modales/category';
import { ProductResponse } from 'src/app/modales/product';
import { ProductDetailes } from 'src/app/modales/ProductDetailes';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  BaseUrl ='http://api.spedosales.apishipping.iraq-soft.info/'
  token :string;
  lang :string;
  constructor(private http: HttpClient) { }

  
  public getAllProducts(id: number):  Observable<ProductResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<ProductResponse>(this.BaseUrl+ 'api/web/get-products/0?cat_id='+ id ,{headers});
  }

  public getAllCategories():  Observable<CategoriesResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<CategoriesResponse>(this.BaseUrl+ 'api/web/get-category' ,{headers});
  }
  public getSingleProduct(id :number):  Observable<ProductDetailes>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<ProductDetailes>(this.BaseUrl+ 'api/web/get-product/'+ id,{headers});
  }
}

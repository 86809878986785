    <!-- Start Page content -->
    <div class="content">
        <div class="container-fluid">

            <div class="row">
                <div class="col-12">
                    <div >
                        <h2>سلة المنتجات</h2>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card-deck mt-5" style="min-height: 500px;" *ngIf="loadingProduct">
                        <div class="logoIcon">
                            <img class="loadingPage" style="width: 80px; height: 80px;    top: 50%;
                            position: absolute;
                            left: 44%;" src="../../../../assets/images/loading.jpg">
                        </div>  
                </div>
                    <div class="desProduct" style="min-height: 500px;" *ngIf="!loadingProduct">
                        <div class="cart_empty" *ngIf="cartEmpty">
                            <div class="img">
                                <img src="../../../../assets/images/empty_cart.png">
                            <p style="text-align: center; font-size: 20px;">الذهاب الي <span style="color: red; cursor: pointer;" routerLink="/product">الرئيسية</span></p>
                            </div>
                        </div>
                        <div class="table-responsive-sm" *ngIf="!cartEmpty">
                            <table class="table mobile" >
                                <thead>
                                    <tr class="header_table">
                                        <th>المنتج</th>
                                        <th>الاسم</th>
                                        <th>الشرح</th>
                                        <th>السعر</th>
                                        <th>الكمية</th>
                                        <th>المجموع</th>
                                        <th>مسح</th>
                                    </tr>
                                </thead>
                                
                              </table>
                              <div *ngFor='let item of cart'>
                                        <app-cart-item [item]=item (addToCart)='AddProduct($event)'></app-cart-item>
                                    
                                    
                              </div>
                              <table class="table">
                                <tbody>
                                    <tr>
                                        <td colspan="4"></td>
                                        <td>المجموع</td>
                                        <td colspan="2">{{totalValue}}</td>
                                    </tr>
                                </tbody>
                                
                              </table>
                        </div>
                        
                       
                        <div class="Cheackout" *ngIf="!cartEmpty">
                            <a class="dataY">
                                تسجيل البيانات الخاصة بك
                            </a>
                            <a class="cart_left" routerLink="/CheckOut">
                                ارسال الفاتورة <i class="fa fa-shopping-cart"></i>
                            </a>
                        </div>
                    </div>
                </div>  
                
            </div>
        </div> <!-- container -->
    </div> <!-- content -->





    
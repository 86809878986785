<!-- Top Bar Start -->
<div class="topbar">

    <nav class="navbar-custom">

        <ul class="list-unstyled topbar-right-menu float-right mb-0">

            <li class="dropdown notification-list">
                <ul class="cartIcon">
                    <li routerLink="/shopingCart">
                        <!-- <span class="fa-stack fa-2x has-badge" data-count="5">
                            <i class="fa fa-circle fa-stack-2x fa-inverse"></i>
                            <i  class="fa fa-shopping-cart fa-stack-2x red-cart"></i>
                          </span> -->
                          <span class="sumCart">
                            <i class="fa fa-shopping-cart"></i>
                            <span>{{cartCount}}</span>
                          </span>
                    </li>
                </ul>
            </li>

        </ul>

        <ul class="list-inline menu-left mb-0">
            <li routerLink="/product" style="padding-right: 20px;">
                <img class="spedo" src="../../../../assets/images/logo_sm.png"> 
                <!-- <div class="page-title-box">
                </div> -->
            </li>

        </ul>

    </nav>

</div>
<!-- Top Bar End -->

<table class="table">
    <tbody>
        <tr>
            <td style="width: 14.2%;" class="imageCart">
                <img src="{{item.image}}">
            </td>
            <td style="width: 14.2%;">{{item.name}}</td>
            <!-- <td style="width: 14.2%;">{{item.desc}}</td> -->
            <td style="width: 14.2%;"> السعر : {{item.price}}{{item.currency}}</td>
            <td style="width: 14.2%;">
                <div class="numProduct">
                    <span class="input-group-text minus cursor-pointer" (click)="travelersPlus($event)">+</span>
                    <!-- <input type="text" class="form-control value text-center" value="{{changeCount}}" /> -->
                    <span class="value">{{changeCount}}</span>
                    <span class="input-group-text pluse cursor-pointer" (click)="travelersMinus($event)">-</span>
                </div>
            </td>
            <td style="width: 14.2%;">السعر الاجمالي : {{item.total_price}} {{item.currency}}</td>
            <td style="width: 14.2%;">
                <ul class="StatusIcon">
                    <li class="delete" (click)='clickSweet(item.id)' ><i class="fa fa-trash"></i></li>
                </ul>
                
            </td>
        </tr>
    </tbody>
</table>
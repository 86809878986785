    <!-- Start Page content -->
    <section class="loading lodingPage" *ngIf="loading">
        <div class="logoIcon">
            <img class="loadingPage" src="../../../../assets/images/loading.jpg">
        </div>  
    </section>
    <div class="content" *ngIf="!loading">
        <div class="container-fluid">
            
            <div class="row" >
                <div class="col-12">
                    <div class="card-box">
                        <div class="topHeader">
                            <div id="container">
                                <div id="objects">
                                  <div class="object " [ngClass]="{'activeCategory': selectedCategoryId === item.id}" *ngFor='let item of categories' (click)="ShowProduct(item.id)">{{item.name}} </div>
                                  
                                </div>
                              </div>
                        </div>
                        <div class="card-deck mt-5" style="min-height: 500px;" *ngIf="loadingProduct">
                                <div class="logoIcon">
                                    <img class="loadingPage" style="width: 80px; height: 80px;    top: 60%;
                                    position: absolute;
                                    left: 40%;" src="../../../../assets/images/loading.jpg">
                                </div>  
                        </div>
                        <div class="card-deck mt-5"  *ngIf="!loadingProduct">
                            <div class="col-md-3 "  *ngFor='let item of products'>
                                <app-single-product [item]=item (childEdited)='AddProduct($event)'></app-single-product>
                            </div>

                        </div>  
                    </div>
                </div>
            </div>
        </div> <!-- container -->
    </div> <!-- content -->
<div class="shopping_cart" *ngIf="!loading">
    <h3> قائمة التسوق</h3>
    <hr>
    <div *ngIf="!cartEmpty" class="all_item">
        <div *ngFor='let item of cart'>
            <div class="descDiv">
                <h5 class="card-title mt-2">{{item.name}}
                </h5>    
                <p>{{item.price}} {{item.currency}} </p>
            </div>
            <img src="{{item.image}}">
            <hr>    
        </div>
    </div>
    <div class="emptyCart" *ngIf="cartEmpty">
        <p>لا يوجد منتجات في السلة</p>
    </div>
    <div *ngIf="!cartEmpty">
        <h3>
            <span> المجموع</span> : {{totalValue}}
        </h3>
        <a class="add_cart clear" (click)="removeAll()">
            مسح
        </a>
        <a class="add_cart cl"  routerLink="/shopingCart">
            الدفع
        </a>
    </div>
</div>


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthResponse } from 'src/app/modales/auth';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  BaseUrl ='http://api.spedosales.apishipping.iraq-soft.info/'
  token :string;
  lang :string;
  constructor(private http: HttpClient) { }

  public login(body):  Observable<AuthResponse>{
    // this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    // .set("Authorization","Bearer " + this.token);
    return this.http.post<AuthResponse>(this.BaseUrl+ 'api/Auth_general/login' ,body,{headers});
  }
}

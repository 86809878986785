import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductDetailsComponent } from './components/pages/product-details/product-details.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { SaveOrderComponent } from './components/pages/save-order/save-order.component';
import { ShopingCartComponent } from './components/pages/shoping-cart/shoping-cart.component';
import { SplahScreenComponent } from './components/pages/splah-screen/splah-screen.component';


const routes: Routes = [
  { path: '',pathMatch: 'full', redirectTo: '/splashScreen' },
  { path: 'product', component: ProductsComponent },
  { path: 'productDetaile', component: ProductDetailsComponent },
  { path: 'shopingCart', component: ShopingCartComponent },
  { path: 'splashScreen', component: SplahScreenComponent },
  { path: 'CheckOut', component: SaveOrderComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { scrollPositionRestoration :'top'},
    
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { NumberFormatStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DataCart, ShoppingCart } from 'src/app/modales/cart';
import { CartService } from 'src/app/services/cart/cart.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shoping-cart',
  templateUrl: './shoping-cart.component.html',
  styleUrls: ['./shoping-cart.component.scss']
})
export class ShopingCartComponent implements OnInit {
  maximumQuantity =10 ; 
  cart :DataCart[] ;
  cartEmpty = false;
  loadingProduct = false; 
  totalValue;
  bodyData : FormData = new FormData;
  constructor(private http : CartService) { }

  ngOnInit(): void {
    this.loadingProduct=true;
    this.http.getCart().subscribe(response =>{
      this.loadingProduct=false;
      this.cart =response.data;
      if(this.cart?.length > 0){
        this.totalValue = this.cart.filter((item) =>item.price)
                            .map((item) => +item.price)
                            .reduce((sum, current) => sum + current);
      }
      if(this.cart?.length === 0){
        this.cartEmpty = true;
        
      }
      console.log(this.cart?.length )
    })
  }


  AddProduct(e){
    this.http.getCart().subscribe(response =>{
      this.cartEmpty = false;
      this.cart = response.data;
      if(this.cart?.length > 0){
        this.totalValue = this.cart.filter((item) =>item.price)
                            .map((item) => +item.price)
                            .reduce((sum, current) => sum + current);
      }

      if(this.cart?.length == 0){
        this.cartEmpty = true;
      }
    })
  }
}

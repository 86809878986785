import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Data } from 'src/app/modales/ProductDetailes';
import { CartService } from 'src/app/services/cart/cart.service';
import { ProductService } from 'src/app/services/products/product.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  productDetaileId : number;
  productDetailse :Data;
  loadingProduct = false;
  bodyData : FormData = new FormData;
  loadingCart =false;
  qty = 1;
  constructor(private http : ProductService,public router: Router,public route: ActivatedRoute, private httpCart : CartService) {
    route.params.subscribe(params => {
      this.productDetaileId = params['productDetaileId'];
  });
}

  ngOnInit(): void {
    this.loadingProduct= true;
    this.http.getSingleProduct(this.productDetaileId).subscribe(response =>{
      this.loadingProduct = false;
      this.productDetailse = response.data;
    })
  }


  productSlide = {
    speed:300,
    slidesToShow:1,
    slidesToScroll:1,
    cssEase:'linear',
    fade:true,
    draggable:true,
    asNavFor:".sliderFor",
  };
  sliderFor = {
    speed:300,
    slidesToShow:3,
    slidesToScroll:1,
    cssEase:'linear',
    centerMode:true,
    draggable:true,
    focusOnSelect:true,
    asNavFor:".productSlide",
    prevArrow:'.client-thumbnails .prev-arrow',
    nextArrow:'.client-thumbnails .next-arrow',
  };
  submitCart(id){
    this.loadingCart = true;

    this.bodyData.append('qty' ,this.qty+'' ),
    this.bodyData.append('product_id' ,id ),
    this.httpCart.addProduct(this.bodyData).subscribe(response =>{
      this.loadingCart = false;
      if(response.status == 1){
        Swal.fire({
          title: 'Success',
          text: response.message,
          icon: 'success',
          confirmButtonText: 'OK',
        })
      }
      
    })
    
  }

}
